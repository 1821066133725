<template>
    <div>
        <el-drawer title="复制广告系列" :visible="visible" size="500px" @close="$emit('close')">
            <div class="copy-ad-series-container">
              <el-container>
                <el-main>
                  <!--广告系列列表-->
                  <div class="ad-series-wrapper">
                    <div class="ad-series-title">
                      <el-checkbox :indeterminate="checkedAdSeries.length>0&&checkedAdSeries.length<adSeriesArr.length" :value="checkedAdSeries.length===adSeriesArr.length" @change="handleCheckAllChange">已选{{checkedAdSeries.length}}项</el-checkbox>
                    </div>
                    <div class="ad-series-content">
                      <el-checkbox-group v-model="checkedAdSeries" @change="handleCheckedAdSeriesChange">
                        <el-checkbox v-for="adSeries in adSeriesArr" :label="adSeries" :key="adSeries.id" :disabled="checkResult && needCheck(adSeries) && !adSeries.$checkResult.pass" :checked="adSeries.checked">
                          <div class="ad-series-item">
                            <div class="name">{{adSeries.name}}</div>
                            <!-- 复制状态 -->
                            <span v-loading="results[adSeries.id]?!results[adSeries.id].done:false" v-if="begin" style="width:60px;text-align:center">
                              <!-- 没有错误信息 -->
                              <i class="el-icon-circle-check" v-if="results[adSeries.id]&&!results[adSeries.id].hasError" style="color:#67C23A"></i>
                              <!-- 有错误信息 -->
                              <el-tooltip placement="top-start" width="200" trigger="hover"  v-if="results[adSeries.id]&&results[adSeries.id].messages">
                                  <p v-for="(message,index) in results[adSeries.id].messages" :key="index" class="resultMessage" slot="content">
                                      <span :class="['point',message.level]"></span>
                                      <span>{{message.message}}</span>
                                  </p>
                                  <i class="el-icon-info"></i>
                              </el-tooltip>
                            </span>
                            <span v-loading="checking" v-if="checking">检测中...</span>
                            <div class="status" v-if="checkResult && needCheck(adSeries)">
                              <i class="el-icon-check" style="color: #47c30f;" v-if="adSeries.$checkResult.pass">成功</i>
                              <el-tooltip class="item" effect="dark" placement="left" v-if="!checking">
                                <i class="el-icon-close" style="color: #ff0000;" v-if="!adSeries.$checkResult.pass">失败</i>
                                <div slot="content" v-if="adSeries.$checkResult.checkResults.filter(s=>s.level!=='ok').length>0">
                                  <div v-for=" (res,i) in adSeries.$checkResult.checkResults.filter(s=>s.level!=='ok')" :key="i">
                                    <i  :class="notice[res.level].icon" :style="{color: notice[res.level].color}"></i>{{res.message}}<br/>
                                  </div>
                                </div>
                              </el-tooltip>
                            </div>
                          </div>
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                  <!--复制广告系列的表单-->
                  <el-form :model="adSeriesFormObj" :rules="adSeriesFormRules" label-width="120px" size="small">
                    <!-- 复制到个号 -->
                    <el-form-item label="复制至" prop="toThirdUserId">
                      <el-select v-model="adSeriesFormObj.toThirdUserId" placeholder="复制至个号"  @change="chooseCopyToNum">
                        <el-option-group v-for="group in numsOptions" :key="group.label" :label="group.label">
                          <el-option v-for="item in group.options" :key="item.id" :label="`${item.thirdUser.displayName ? item.thirdUser.displayName : item.thirdUser.thirdUserName}（ ${item.thirdUser.thirdUserEmail}）`" :value="item.id">
                            <i class="el-icon-info"></i>
                            <span>{{`${item.thirdUser.displayName ? item.thirdUser.displayName : item.thirdUser.thirdUserName}（ ${item.thirdUser.thirdUserEmail}）`}}</span>                            
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="复制至" prop="toAccountId">
                      <el-select v-model="adSeriesFormObj.toAccountId" @change="chooseCopyToAccount" :disabled="!flag&&!isInAccountCopy&&!notCurrentNum">
                        <el-option-group v-for="group in !notCurrentNum?accountsOptions:notCurrentNumAccountsOptions" :key="group.label" :label="group.label">
                          <el-option v-for="item in group.options" :key="item.id" :label="item.name" :value="item.id" :disabled="!item.$warn.pass" value-key="id">
                            <i class="el-icon-info"></i>
                            <span>{{item.name}}</span>
                            <el-tooltip class="item" effect="dark" :content="item.$warn.msg" placement="left" v-if="item.$warn.warn">
                              <i class="el-icon-warning-outline" :style="{color: item.$warn.color}"></i>
                            </el-tooltip>
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="复制份数" prop="copys" v-if="isInAccountCopy">
                      <el-input v-model="adSeriesFormObj.copys" type="number" max="10" min="1" :inline="true" style="width: 100px; margin-right: 10px;"></el-input>
                      <span>最大10份</span>
                    </el-form-item>
                    <el-form-item label="主页" prop="targetPageId" v-if="!isInAccountCopy">
                      <el-select v-model="adSeriesFormObj.targetPageId" placeholder="请选择主页" @change="checkResult = null" v-loading="pageLoading">
                          <el-option :label="page.name" :value="page.id" style="height:70px" v-for="page in accountPagesList" :key="page.id" :disabled="!page.is_published">
                              <div class="pageItems">
                                  <div class="pageImg">
                                      <el-image :src="page.picture_url"></el-image>
                                  </div>
                                  <div class="pageMsg">
                                      <p>{{page.name}}</p>
                                      <div>编号：{{page.id}}</div>
                                  </div>
                              </div>
                          </el-option>
                          <div slot="empty" class="empty" v-loading="!flag1">
                              <i class="iconfont icon-wushuju"></i>
                              <p>暂无数据</p>
                          </div>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="像素" prop="targetPixelId" v-if="!isInAccountCopy">
                      <el-select v-model="adSeriesFormObj.targetPixelId" placeholder="请选择像素" @change="checkResult = null" v-loading="pixelLoading">
                          <el-option :label="page.name" :value="page.pixel_id" v-for="page in pixelList" :key="page.id">
                              {{page.name}}
                          </el-option>
                          <div slot="empty" class="empty"  v-loading="!flag2">
                            <i class="iconfont icon-wushuju"></i>
                            <p>暂无数据</p>
                        </div>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="推广链接" prop="targetUrl" v-if="!isInAccountCopy" @change="checkResult = null">
                      <el-input v-model="adSeriesFormObj.targetUrl"></el-input>
                    </el-form-item>
                    <el-form-item label="复制后状态" prop="adStatus">
                      <el-radio-group v-model="adSeriesFormObj.isUpload"  v-if="!isInAccountCopy">
                        <el-radio-button :label="false">存入草稿箱</el-radio-button>
                        <el-radio-button :label="true">上传至Facebook</el-radio-button>
                      </el-radio-group>

                      <el-radio-group v-model="adSeriesFormObj.adStatus">
                        <el-radio label="PAUSED">暂停</el-radio>
                        <el-radio label="ACTIVE">开启</el-radio>
                        <el-radio label="INHERITED_FROM_SOURCE">维持原状</el-radio>
                      </el-radio-group>
                      <div>复制后的广告系列投放状态为{{adSeriesFormObj.adStatus == 'INHERITED_FROM_SOURCE' ? '维持原状' : (adSeriesFormObj.adStatus == 'PAUSED' ? '暂停' : '开启')}}</div>
                    </el-form-item>
                  </el-form>
                </el-main>
                <el-footer>
                  <el-row type="flex"  justify="end">
                    <span>
                      <el-button @click="$emit('close')">取消</el-button>
                      <el-button type="primary" @click="campaignsCheck" v-show="!canCopy">检测</el-button>
                      <el-button type="primary" @click="confirmCopy" v-show="canCopy" :disabled="can">复制</el-button>
                    </span>
                  </el-row>
                </el-footer>
              </el-container>
            </div>
        </el-drawer>
    </div>
</template>
<script>
  import _ from 'lodash'
  import {   copyCampaignsCheck, copyCampaigns,adaccoutsHeader,accountPagesHeader,accountPixelHeader} from '@/api/adManagement.js'
  // import {accountPages,accountPixel} from '@/api/creatAd.js'
  import copyProcess from './copyProcess'
  import {mapState,mapGetters} from "vuex"
  export default {
    props:['visible','editRows'],
    mixins:[copyProcess],
    data() {
      var checkUrl = (rule, value, callback) => {
        let reg = new RegExp(/^((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/)
        if(value&&!reg.test(value)) {
          callback(new Error('请输入正确的网址'));
        } else {
          callback();
        }
      };
      return {
        pageLoading:false,
        pixelLoading:false,
        showCopyAdSeries: false,  // 是否展示复制广告系列的弹窗
        isIndeterminate: false,  // 是否选中全部(只选中样式)
        checkedAdSeries: [],  // 已选中的广告系列
        adSeriesArr: [],  // 全部广告系列的数组
        // 复制广告系列的表单
        adSeriesFormObj: {
          toThirdUserId: "",
          toAccountId: '',
          copys: 1,
          adStatus: 'PAUSED',
          isUpload: true,
        },
        // 复制广告系列的表单验证
        adSeriesFormRules: {
          toAccountId: [
            { required: true, message: '请选择需要复制到哪个账户', trigger: 'blur' },
          ],
          copys: [
            { required: true, message: '请输入复制的份数', trigger: 'blur' },
          ],
          adStatus: [
            { required: true, message: '请选择复制后的状态', trigger: 'blur' },
          ],
          targetPageId:[
            {required: false, message: '选择主页', trigger: 'blur' }
          ],
          targetPixelId:[
            {required: false, message: '选择像素', trigger: 'blur' }
          ],
          targetUrl:[
            {validator: checkUrl, trigger: 'blur' }
          ],
        },
        // 账户列表
        accountsList: [],
        currentAccount: {},
        accountPagesList:[],
        pixelList:[],
        checkResult: null,
        checking: false,
        notice:{
          error:{
            icon:'el-icon-circle-close',
            color:'#ff0000',
          },
          warn:{
            icon:'el-icon-warning-outline',
            color:'#faad14',
          },
          ok:{
            icon:'el-icon-circle-check',
            color:'#47c30f',
          },
        },
        begin:false,
        results:{},
        flag:false,
        flag1:false,
        flag2:false,
        // 个号相关
        currentNum:{},
        notCurrentNum:false,
        // 不是当前个号下的广告账号列表
        notCurrentNumAccounts:[]
      }
    },
    computed:{
      ...mapState("num", ["selectedNum"]),
      ...mapGetters("num", ["validNumLists"]),
      isInAccountCopy(){
        if(!this.notCurrentNum){
          return this.adSeriesFormObj.toAccountId===this.currentAccount.id;
        }else{
          return false
        }
      },
      numsOptions(){
        this.init();
        return [
          { label: '当前个号', options: this.validNumLists.filter(item=>item.id==this.selectedNum)  },
          { label: '其他个号', options: this.validNumLists.filter(item=>item.id!=this.selectedNum) },
          ];
      },
      notCurrentNumAccountsOptions(){
        return [
          { label: '广告账号', options: this.notCurrentNumAccounts.map(it=>{it.$warn=this.accountWarn(it);return it;}) },
          ];
      },
      accountsOptions(){
        this.currentAccount.$warn=this.accountWarn(this.currentAccount);
        return [
          { label: '当前广告账号', options: [this.currentAccount] },
          { label: '其他广告账号', options: this.accountsList.filter(item=>item.id!==this.currentAccount.id).map(it=>{it.$warn=this.accountWarn(it);return it;}) },
          ];
      },
      canCopy(){
        if(this.isInAccountCopy)return true;
        if(!this.checkResult)return false;
        if(this.checkedAdSeries.some((adSeries => !adSeries.$checkResult.pass)))return false;
        return true;
      }
    },
    methods: {
      // 获取广告账户列表
      adaccouts(id) {
        let params = {
          keyword: '',
          limit: 10000,
          page: 1,
          id
        }
        this.$showLoading()
       return adaccoutsHeader(params).then(res => {
          this.$hideLoading()
          if(res.code == 200) {
           this.notCurrentNumAccounts=res.data.list && res.data.list.length ? [...res.data.list] : []
          }
        })
      },
      messages(msg){
        if(msg){
          return msg.messages
        }else{
          return []
        }
      },
      needCheck(campaign){
        return this.checkedAdSeries.some(f=>f.id===campaign.id);
      },
      campaignCheckResult(campaign){
        if(this.checking){
          return {
            checkResults: [{
              level:'error',
              message:'检测中',
            }],
            pass: false,
          };
        }
        let res={
          checkResults: [],
          pass: true,
        };
        if(!this.checkResult)return  res;
        if(this.checkResult.accountCheckResult){
          res=this.checkResult.accountCheckResult;
          if(!res.pass){
            return  res;
          }
        }
        let campaignRes=this.checkResult.checkResultMap[campaign.id];
        res.checkResults=res.checkResults.concat(campaignRes.checkResults);
        res.pass=campaignRes.pass;
        return res;
      },
      open() {
        this.showCopyAdSeries = true
      },
      close() {
        this.showCopyAdSeries = fasle
      },
      accountWarn(account){
        let res={
          pass:true,
          warn:false,
          msg:'通过',
          color:'#47c30f',
        };
        if(account.accountStatus===2){
          res.pass=false;
          res.warn=true;
          res.msg= "广告账户不可用";
          res.color='#ff0000';
        }else if(account.timezoneName!==this.currentAccount.timezoneName){
          res.msg= "广告账户时区不一致";
          res.warn=true;
          res.color='#faad14';
        }else if(account.currency.key!==this.currentAccount.currency.key){
          res.msg= "广告账户币种不一致";
          res.warn=true;
          res.color='#faad14';
        }
        return res;
      },
      confirmCopy() {
        const data = {
          campaignIds: [],
          adStatus: this.adSeriesFormObj.adStatus,
          accountId: this.$store.getters.accountId,
          copys: this.adSeriesFormObj.copys,
          toThirdUserId: this.adSeriesFormObj.toThirdUserId,
          toAccountId: this.adSeriesFormObj.toAccountId,
          upload: this.adSeriesFormObj.isUpload,
          replaceOption:{
            targetPageId:this.adSeriesFormObj.targetPageId,
            targetPixelId:this.adSeriesFormObj.targetPixelId,
            targetUrl:this.adSeriesFormObj.targetUrl,
          }
        }
        
        if(this.checkedAdSeries.length == 0) {
          this.$message.error('请至少选择一个广告系列')
          return false
        }
        this.checkedAdSeries.forEach(item => {
          data.campaignIds.push(item.id)
        })
        this.$showLoading()
        this.begin = true;
        copyCampaigns(JSON.stringify(data)).then(res => {
          // console.log(res)
          this.$hideLoading();
          // this.begin = false;
          if(res.code == 0) {
            // console.log(res.data.resultContext);
            if(this.isInAccountCopy){
              // this.$message.info('复制成功，异步复制中，稍后刷新列表查看结果')
              this.$message.info('复制命令已提交，fb在后台复制，现在可以去做别的事情了，或者继续等待复制结果！')
              this.results = res.data.resultContext.resultMap.results;
              this.getProcess(res.data.resultContext);
            }else{
              if(this.adSeriesFormObj.isUpload){
                this.$message.info('已存入草稿箱，并上传中，请到草稿箱查看上传状态')
              }else{
                this.$message.info('已存入草稿箱')
              }
              this.$emit('close');
            }
            // this.$emit('close');
          }else{
            this.$message.error(res.comment)
          }
        })
      },
      campaignsCheck() {
        this.checking= true;
        this.checkResult= null;
        const data = {
          campaignIds: [],
          adStatus: this.adSeriesFormObj.adStatus,
          accountId: this.$store.getters.accountId,
          copys: this.adSeriesFormObj.copys,
          toThirdUserId: this.adSeriesFormObj.toThirdUserId,
          toAccountId: this.adSeriesFormObj.toAccountId,
          replaceOption:{
            targetPageId:this.adSeriesFormObj.targetPageId,
            targetPixelId:this.adSeriesFormObj.targetPixelId,
            targetUrl:this.adSeriesFormObj.targetUrl,
          }
        }
        if(this.checkedAdSeries.length == 0) {
          this.$message.error('请至少选择一个广告系列')
          return false
        }
        this.checkedAdSeries.forEach(item => {
          data.campaignIds.push(item.id)
        })
        // this.$showLoading()
        copyCampaignsCheck(JSON.stringify(data)).then(res => {
          if(res.code == 0) {
            this.checkResult=res.data;
            this.checking= false;
            this.checkedAdSeries.forEach(camp=>{camp.$checkResult=this.campaignCheckResult(camp)})
          }else{
            this.checkResult = null;
            this.checking= false;
          }
        })
      },
      closeCopyAdSeries() {
      },
      // 全选
      handleCheckAllChange(v) {
        // console.log(v)
        this.checkedAdSeries = v ? this.adSeriesArr : []
      },
      // 广告系列改变
      handleCheckedAdSeriesChange(v) {
      },
      async getAcoountPages(id){
          this.pageLoading=true;
          this.pixelLoading=true;
          this.flag1= false;this.flag2=false;
          // await accountPages({accountId:id}).then(res=>{
          await accountPagesHeader({accountId:id},this.currentNum.id).then(res=>{
              // console.log(res);
              let canUse = res.data.filter(v=>v.is_published);
              let notUse = res.data.filter(v=>!v.is_published);
              this.accountPagesList = canUse.concat(notUse);
              this.flag1 = true;
              this.pageLoading=false;
          })
          // await accountPixel({accountId:id},this).then(res=>{
          await accountPixelHeader({accountId:id},this,this.currentNum.id).then(res=>{
              // console.log(res);
              this.pixelList = res.data;
              this.flag2 = true;
              this.pixelLoading=false;
          });
          this.flag = this.flag1&&this.flag2
      },
      // 初始化
      init() {
        // console.log("this.editRows",this.editRows)
        this.adSeriesArr = _.cloneDeep(this.editRows);
        // console.log(this.adSeriesArr);
        this.checking= false;
        this.checkResult= null;
        this.checkedAdSeries=this.adSeriesArr;
        // console.log('22222');
        this.accountsList =  _.cloneDeep(this.$store.getters.accountsList);
        this.currentAccount= _.cloneDeep(this.$store.getters.currentAccountInfo);

        this.adSeriesFormObj= {
          toThirdUserId: this.selectedNum,
          toAccountId: this.currentAccount.id,
          copys: 1,
          adStatus: 'PAUSED',
          isUpload: false,
        }
      },
      checkedItem(item){
        item.checked=true;
      },
      // 选择要复制到的个号
      async chooseCopyToNum(v){
        this.currentNum=this.validNumLists.find(item=>item.id==v)
        if(v!=this.selectedNum){
          // console.log("选中的不是当前个号！")
          this.$set(this.adSeriesFormObj,'toAccountId',"")
          this.$set(this.adSeriesFormObj,'targetPageId',"")
          this.$set(this.adSeriesFormObj,'targetPixelId',"")
          this.notCurrentNum=true;
          await this.adaccouts(v)
        }else{
          this.notCurrentNum=false;
        }
      },
      // 选择要复制到的账号,判断选中的账号是否需要进行检测
      chooseCopyToAccount(v) {
        // console.log(v);
        this.$set(this.adSeriesFormObj,'targetPageId',"")
        this.$set(this.adSeriesFormObj,'targetPixelId',"")
        this.flag = false
        this.checkResult=null;
        this.accountPagesList = [];
        this.pixelList = [];
        this.getAcoountPages(this.adSeriesFormObj.toAccountId);
      }
    }
  }
</script>

<style lang='scss' scoped>
  .copy-ad-series-container{
    .ad-series-wrapper{
      margin-bottom: 20px;
      .ad-series-title{
        background: rgb(230, 241, 255);
        color: rgb(34, 135, 236);
        line-height: 30px;
        padding: 0px 10px;
        display: flex;
      }
      .ad-series-content{
        max-height: 300px;
        overflow: auto;
        border-width: 0px 1px 1px;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-right-color: rgb(229, 230, 233);
        border-bottom-color: rgb(229, 230, 233);
        border-left-color: rgb(229, 230, 233);
        border-image: initial;
        border-top-style: initial;
        border-top-color: initial;
        padding: 10px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
</style>
<style lang="scss">
  .resultMessage{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .point{
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      &.success{
        background: #67C23A;
      }
      &.warn{
        background: #E6A23C;
      }
      &.success{
        background: #F56C6C;
      }
    }
  }
  .copy-ad-series-container{
    .ad-series-wrapper{
      .ad-series-content{
        .el-checkbox{
          width: 100%;
          .el-checkbox__label{
            width: 98%;
            .ad-series-item{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 30px;
              line-height: 30px;
              cursor: pointer;
              .name{
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .name:hover{
                background: rgb(230, 241, 255);
              }
              .status{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 10%;
              }
            }
          }
        }
      }
    }
    .el-loading-spinner{
      margin-top: -14px;
      .circular{
        width: 28px;
        height: 28px;
      }
    }
  }
</style>
