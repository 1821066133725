<template>
    <el-drawer :title="'复制'+levels[level].desc" :visible="visible" size="500px" @close="close" @open="init">
        <div class="copy-ad-series-container">
            <el-container>
                <el-main>
                    <div class="ad-series-wrapper">
                        <div class="ad-series-title">
                            <el-checkbox
                                    :indeterminate="checkedAdSeries.length>0&&checkedAdSeries.length<adSeriesArr.length"
                                    :value="checkedAdSeries.length===adSeriesArr.length" @change="handleCheckAllChange">
                                已选{{checkedAdSeries.length}}项
                            </el-checkbox>
                        </div>
                        <!-- {{adSeriesArr}} -->
                        <div class="ad-series-content">
                            <el-checkbox-group v-model="checkedAdSeries" >
                                <el-checkbox v-for="adSeries in adSeriesArr" :label="adSeries" :key="adSeries.id">
                                    <div class="ad-series-item">
                                        <div class="name">{{adSeries.name}}</div>
                                        
                                        <!-- 复制状态 -->
                                        <span v-loading="results[adSeries.id]?!results[adSeries.id].done:false" v-if="begin" style="width:60px;text-align:center">
                                        <!-- 没有错误信息 -->
                                        <i class="el-icon-circle-check" v-if="results[adSeries.id]&&!results[adSeries.id].hasError" style="color:#67C23A"></i>
                                        <!-- 有错误信息 -->
                                        <el-popover placement="top-start" title="复制结果" width="300" trigger="hover"  v-if="results[adSeries.id]&&results[adSeries.id].hasError">
                                            <p v-for="(message,index) in results[adSeries.id].messages" :key="index" class="resultMessage">
                                                <span :class="['point',message.level]"></span>
                                                <span>{{message.message}}</span>
                                            </p>
                                            
                                            <i slot="reference" class="el-icon-info" v-if="results[adSeries.id]"></i>
                                        </el-popover>
                                        </span>
                                    </div>
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <el-form :model="adSeriesFormObj" :rules="adSeriesFormRules" label-width="120px" size="small" style="margin-top:10px">
                            <el-form-item label="复制至" >
                                <el-select v-model="target" filterable reserve-keyword placeholder="请输入关键词" :loading="loading">
                                    <!-- {{options}} -->
                                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                                    <div slot="empty" class="empty">
                                        <i class="iconfont icon-wushuju"></i>
                                        <p>暂无数据</p>
                                    </div>
                                </el-select>
                                <!-- <span>当前 {{levels[level].parent}}</span> -->
                            </el-form-item>
                            <el-form-item label="复制份数" prop="copys">
                                <el-input v-model="adSeriesFormObj.copys" type="number" max="10" min="1" :inline="true" style="width: 100px; margin-right: 10px;"></el-input>
                                <span>最大10份</span>
                            </el-form-item>
                            <el-form-item label="复制后状态" prop="adStatus">
                                <el-radio-group v-model="adSeriesFormObj.adStatus">
                                    <el-radio label="PAUSED">暂停</el-radio>
                                    <el-radio label="ACTIVE">开启</el-radio>
                                    <el-radio label="INHERITED_FROM_SOURCE">维持原状</el-radio>
                                </el-radio-group>
                                <div>复制后的投放状态为{{adSeriesFormObj.adStatus == '' ? '维持原状' : (adSeriesFormObj.adStatus == 'PAUSED' ? '暂停' : '开启')}}</div>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-main>
                <el-footer>
                    <el-row type="flex" justify="end">
                    <span>
                      <el-button @click="close">取消</el-button>
                      <el-button type="primary" @click="confirmCopy" :disabled="can">复制</el-button>
                    </span>
                    </el-row>
                </el-footer>
            </el-container>
        </div>
    </el-drawer>

</template>

<script>
    import _ from "lodash";
    import {copyAd,report,sameCampaignAdsets} from "@/api/adManagement";
    import copyProcess from './copyProcess'
    import {fnCSTToYmd} from "@/utils/utils";
    export default {
        name: "CopyAdset",
        // props: ['visible', 'editRows','level'],
        props:{
            visible:{
                type:Boolean,
                default:false
            },
            editRows:{
                type:Array,
                default:(()=>[])
            },
            level:{
                type:String,
                default:'adset'
            }
        },
        mixins:[copyProcess],
        data() {
            return {
                checkedAdSeries: [],  // 已选中的
                adSeriesArr: [],  // 全部的
                levels: {
                    adset: {
                        desc:"广告组",
                        parent:"广告系列",
                    },
                    ad: {
                        desc:"广告",
                        parent:"广告组",
                    },
                },
                adSeriesFormObj: {
                    copys: 1,
                    adStatus: 'PAUSED',
                },
                adSeriesFormRules: {
                    copys: [
                        { required: true, message: '请输入复制的份数', trigger: 'blur' },
                    ],
                    adStatus: [
                        { required: true, message: '请选择复制后的状态', trigger: 'blur' },
                    ],
                },
                begin:false,
                results:{},
                target:null,
                loading:false,
                optionsDef:[],
                options:[],
                params:{
                    level:'campaign',
                    keyword:'',
                    account_id:this.$store.getters.accountId,
                    object_filtering:[],
                    fields:['name','id'],
                    action_attribution_windows:[],
                    filtering:[],
                    date_start: '',
                    date_end: ''
                }
            }
        },
        methods: {
            close(){
                this.$emit('close');
                this.target = null
            },
            init() {
                // console.log(this.editRows);
                this.adSeriesArr = _.cloneDeep(this.editRows);
                this.checkedAdSeries = this.adSeriesArr;
                this.adSeriesFormObj.copys=1;
                this.adSeriesFormObj.adStatus='PAUSED';
                this.optionsDef = [{name:`当前${this.levels[this.level].parent}`,id:null}];
                this.options = this.optionsDef;
                if(this.level == 'ad'){
                    this.getList();
                }
            },
            handleCheckAllChange(v) {
                // console.log(v)
                this.checkedAdSeries = v ? this.adSeriesArr : []
            },
            confirmCopy() {
                const data = {
                    adSetIds: [],
                    adIds: [],
                    adStatus: this.adSeriesFormObj.adStatus,
                    accountId: this.$store.getters.accountId,
                    copys: this.adSeriesFormObj.copys,
                }
                if(this.checkedAdSeries.length == 0) {
                    this.$message.error('请至少选择一个'+this.levels[this.level].desc)
                    return false
                }
                this.checkedAdSeries.forEach(item => {
                    if(this.level==='ad'){
                        data.adIds.push(item.id);
                        data.targetAdsetId = this.target
                    }else{
                        data.adSetIds.push(item.id);
                        data.targetCampaignId = this.target
                    }
                });
                this.$showLoading()
                this.begin = true;
                copyAd(JSON.stringify(data)).then(res => {
                    // console.log(res)
                    this.$hideLoading()
                    if(res.code == 0) {
                        // this.$message.info('复制成功')
                        this.results = res.data.resultMap.results;
                        this.getProcess(res.data);
                        // this.$emit('close');
                    }else{
                        this.$message.error(res.comment)
                    }
                })
            },
            // 获取广告所在的广告系列
            getList(){
                sameCampaignAdsets({adIds:this.adSeriesArr.map(v=>v.id),accountId:this.$store.getters.accountId}).then(res=>{
                    if(res.code == 0){
                        let list = JSON.parse(JSON.stringify(res.data))
                        list.map(v=>{
                            v.id = v.adsetId;
                            v.name=v.adsetName
                        });
                        this.options = this.options.concat(list);
                    }
                   
                })
            },
            // 远程搜索
            remoteMethod(query) {
                this.loading = true;
                    let end = new Date(),
                        start = new Date().getFullYear() + "/" + ((new Date().getMonth()) / 1 + 1) + "/" + new Date().getDate();
                    // start.setTime(start.getTime() - 3600 * 1000 * (24 * (30 - 1)));
                    this.params.date_start = fnCSTToYmd(new Date(start),{tag: 1});
                    this.params.date_end = fnCSTToYmd(end,{tag: 1});
                    this.params.keyword = query;
                    this.params.level = this.level == 'adset'?'campaign':'adset'
                    report(JSON.stringify(this.params)).then(res=>{
                        // console.log(res.data.data);
                        this.loading = false;
                        this.options = this.optionsDef.concat(res.data.data);

                    })
            }
        },

    }
</script>


<style lang='scss' scoped>
    .copy-ad-series-container{
        .ad-series-wrapper{
            margin-bottom: 20px;
            .ad-series-title{
                background: rgb(230, 241, 255);
                color: rgb(34, 135, 236);
                line-height: 30px;
                padding: 0px 10px;
                display: flex;
            }
            .ad-series-content{
                max-height: 300px;
                overflow: auto;
                border-width: 0px 1px 1px;
                border-right-style: solid;
                border-bottom-style: solid;
                border-left-style: solid;
                border-right-color: rgb(229, 230, 233);
                border-bottom-color: rgb(229, 230, 233);
                border-left-color: rgb(229, 230, 233);
                border-image: initial;
                border-top-style: initial;
                border-top-color: initial;
                padding: 10px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
</style>
<style lang="scss">
  .resultMessage{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .point{
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      flex: 0 0 8px;
      &.success{
        background: #67C23A;
      }
      &.warn{
        background: #E6A23C;
      }
      &.success{
        background: #F56C6C;
      }
    }
  }
  .copy-ad-series-container{
    .ad-series-wrapper{
      .ad-series-content{
        .el-checkbox{
          width: 100%;
          .el-checkbox__label{
            width: 98%;
            .ad-series-item{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 30px;
              line-height: 30px;
              cursor: pointer;
              .name{
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .name:hover{
                background: rgb(230, 241, 255);
              }
              .status{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 10%;
              }
            }
          }
        }
      }
    }
    .el-loading-spinner{
      margin-top: -14px;
      .circular{
        width: 28px;
        height: 28px;
      }
    }
  }
</style>