<template>
    <div>

        <!-- <copy-campaign v-if="level==='campaign' " :visible="visible" :edit-rows="selectedRowScopes" @close="closeDrawer">

        </copy-campaign>
        <copy-adset v-else-if="level==='adset'" :level="level" :visible="visible" :edit-rows="selectedRowScopes" @close="closeDrawer">

        </copy-adset>
        <copy-adset v-else-if="level==='ad'" :level="level" :visible="visible" :edit-rows="selectedRowScopes" @close="closeDrawer">

        </copy-adset> -->
        <component :is="which" :level="level" :visible="visible" :edit-rows="selectedRowScopes" @close="closeDrawer" @refresh="refreshList"></component>
    </div>

</template>

<script>
    import CopyCampaign from "@/views/adManagement/components/copy/CopyCampaign";
    import CopyAdset from "@/views/adManagement/components/copy/CopyAdset";

    export default {
        name: "CopyDrawer",
        components: { CopyAdset, CopyCampaign},
        // props: [
        //     'selectedRowScopes',
        //     'level',
        //     'visible',
        // ],
        props:{
            visible:{
                type:Boolean,
                default:false
            },
            selectedRowScopes:{
                type:Array,
                default:(()=>[])
            },
            level:{
                type:String,
                default:'adset'
            }
        },
        computed:{
            which(){
                return this.level == 'ad' ?'copy-adset':'copy-' + this.level
            }
        },
        data() {
            return {
                levels: ['campaign', 'adset', 'ad'],
            }
        },
        methods: {
            closeDrawer() {
                this.$emit('close-drawer')
            },
            refreshList(){
                this.$emit('reload-table')
            }
        },
    }
</script>

<style scoped>

</style>