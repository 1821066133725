import {queryAsyncAdCopyResult} from '@/api/adManagement.js'
export default{
    data() {
        return {
            timer:null,
            flag:true,
            can:false
        }
    },
    methods: {
        getProcess(arr) {
                queryAsyncAdCopyResult(arr).then(res => {
                    this.flag = true;
                    if (res.code == 0) {
                        let can = false;
                        this.results = res.data.resultMap.results;
                        for (let key in res.data.resultMap.results){
                            if (res.data.resultMap.results[key]){
                                if (!res.data.resultMap.results[key].done){
                                    can = true;
                                }
                            }
                        }
                        this.can = can;
                        // console.log(can);
                        if (can && this.visible){
                            setTimeout(() => { this.getProcess(res.data) }, 5000);
                        }else{
                            this.$message({ type: 'success', message: '完成' });
                            this.$emit('refresh')
                        }
                        // this.percentage = res.data.async_percent_completion;
                        // if (res.data.async_status.key == 'Job Completed') {
                        //     // 完成，去下载
                        //     this.downLoad(id, res.data.download_url)
                        // }
                        // if (res.data.async_status.key == 'Job Failed') {
                        //     this.stopInterVal({ type: 'warning', message: '任务失败。考虑重新访问查询，并重试。' })
                        // }
                        // if (res.data.async_status.key == 'Job Skipped') {
                        //     this.stopInterVal({ type: 'warning', message: '任务已过期并跳过。请重新提交任务，并重试。' })
                        // }
                    }
                })
        },
        processFailed(msg) {
            // this.process = false;
            this.$message({ type: 'warning', message: msg });
        },
    },
}